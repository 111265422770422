<template>
    <div class="container">
        <div class="col-wrap header">
            <div class="bg bg-animation2"></div>
            <div class="wrap-content">
                <Nav></Nav>
                <div class="content">
                    <h1>JOIN US</h1>
                    <h3>诚挚邀请英才、汇聚每一份智慧谱写爱创明天</h3>
                    <p>
                        We are glad to have like-minded talents participated in itrax to create a wonderful future
                    </p>
                </div>
            </div>
        </div>
        <div class="col-wrap recruitment">
            <div class="wrap-content">
                <div class="col-title">
                    <h1>招聘岗位</h1>
                    <div>Post</div>
                </div>
                <div class="content">
                    <ul class="city">
                        <li :class="tabIdx == 1 ? 'active' : ''" @click="tabHandle(1)">北京</li>
                        <li :class="tabIdx == 2 ? 'active' : ''" @click="tabHandle(2)">上海</li>
                        <li :class="tabIdx == 3 ? 'active' : ''" @click="tabHandle(3)">广州</li>
                        <li :class="tabIdx == 4 ? 'active' : ''" @click="tabHandle(4)">杭州</li>
                        <li :class="tabIdx == 5 ? 'active' : ''" @click="tabHandle(5)">重庆</li>
                        <li :class="tabIdx == 6 ? 'active' : ''" @click="tabHandle(6)">长春</li>
                    </ul>
                    <ul class="job">
                        <li v-for="item in currentList" :key="item.id">
                            <i></i><a :href="'/recruitment/detail?city=' + item.city + '&id=' + item.id">{{ item.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-wrap">
            <div class="content">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>
<script>
import Nav from "@/components/pc/nav.vue";
import Footer from "@/components/pc/footer.vue";
export default {
    name: "pc-recruitment",
    components: { Nav, Footer },
    data() {
        return {
            tabIdx: 1,
            list: [],
            currentList: [],
        };
    },
    mounted() {
        this.loadData();
    },
    methods: {
        tabHandle(e) {
            this.tabIdx = e;
            this.filterData();
        },
        filterData() {
            var li = this.list.filter((item) => item.city == this.tabIdx);
            this.currentList = li;
        },
        loadData() {
            this.$showLoading();
            this.$http
                .get(`/api/recruitment/list`)
                .then((res) => {
                    this.list = res.data;
                    this.filterData();
                    this.$forceUpdate();
                })
                .finally(() => {
                    this.$hideLoading();
                });
        },
    },
};
</script>
<style scoped>
@import url("../../../common/css/pc.css");
.job {
    flex: 1;
    margin-left: 240px;
}
.job li {
    font-size: 20px;
    display: flex;
    align-items: center;
    margin: 25px 0;
}
.job li i {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: #535353;
    border-radius: 50%;
    margin-right: 25px;
}

.job li a:hover {
    text-decoration: underline;
    color: #0048b1;
}
.city {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.city .active {
    background: #0048b1;
    border: 1px #0048b1 solid;
    color: #fff;
}
.city li:hover {
    background: #0048b1;
    color: #fff;
    border: 1px #0048b1 solid;
}

.col-title {
    margin-top: 100px;
}
.recruitment {
    margin-bottom: 80px;
    background: url("~@/assets/pc/recruitment/bg.jpg") no-repeat center;
    background-size: 100% auto;
}
.recruitment .content {
    margin-left: 64px;
    display: flex;
    align-items: center;
}

.header .wrap-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.header .wrap-content .content {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media only screen and (max-width: 1440px) {
    .header .bg {
        background: url("~@/assets/pc/recruitment/banner-bg.jpg") no-repeat center bottom;
        height: 500px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .city li {
        width: 170px;
        height: 35px;
        border-radius: 28px;
        border: 1px #535353 solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #535353;
        font-size: 15px;
        margin: 10px 0;
        cursor: pointer;
    }
    .job li a {
        font-size: 16px;
        color: #535353;
    }
}

@media only screen and (min-width: 1441px) {
    .header .bg {
        background: url("~@/assets/pc/recruitment/banner-bg.jpg") center bottom;
        height: 760px;
    }
    .header .wrap-content .content h1 {
        font-size: 24px;
    }
    .header .wrap-content .content h3 {
        font-size: 30px;
        margin: 15px 0;
    }
    .header .wrap-content .content p {
        text-align: center;
        font-size: 20px;
    }
    .city li {
        width: 200px;
        height: 40px;
        border-radius: 20px;
        border: 1px #535353 solid;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #535353;
        font-size: 15px;
        margin: 10px 0;
        cursor: pointer;
    }
    .job li a {
        font-size: 16px;
        color: #535353;
    }
}
</style>
